import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { QueryFilterModel } from "../models/notifications/query-filter";

@Injectable({ providedIn: 'root' })
export class QueriesService {
    private http = inject(HttpClient);

    private readonly url: string = `${environment.api.url}notifications-schedule/users-queries`;
    private readonly selectionurl: string = `${environment.api.url}notifications-schedule/`;

    public getQueries(
    ): Observable<QueryFilterModel[]> {
        return this.http.get<QueryFilterModel[]>(this.url);
    }

    public getQueriesCountById(id: number): Observable<number> {
        return this.http.get<number>(`${this.url}/${id}/count`);
    }

    public getUsersCount(query: QueryFilterModel): Observable<number> {
        return this.http.post<number>(`${this.url}/count`, query);
    }

    public getQueryFilter(id: number): Observable<QueryFilterModel> {
        return this.http.get<QueryFilterModel>(`${this.url}/${id}`);
    }

    public addQueryFilter(QueryFilterModel: QueryFilterModel): Observable<QueryFilterModel> {
        return this.http.post<QueryFilterModel>(this.url, QueryFilterModel);
    }
    public updateQueryFilter(QueryFilterModel: QueryFilterModel): Observable<QueryFilterModel> {
        return this.http.put<QueryFilterModel>(this.url, QueryFilterModel);
    }

    public deleteQueryFilter(QueryFilterModelid: number) {
        return this.http.delete(`${this.url}/${QueryFilterModelid}`);
    }

    public countriesSelection(searchString: string): Observable<string[]> {
        return this.http.get<string[]>(`${this.selectionurl}countries?searchString=${searchString}`);
    }

    public citiiesSelection(searchString: string): Observable<string[]> {
        return this.http.get<string[]>(`${this.selectionurl}cities?searchString=${searchString}`);
    }
}