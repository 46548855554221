import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs";
import { AuthService } from "src/app/auth/services/auth.service";
import { CustomerJourneyTemplate } from "src/app/models/notifications/customer-jorney";
import { CustomerJourneyService } from "src/app/services/customer-journey.service";
import { AddCustomerJourney, ApproveCustomerJourney, ApproveCustomerJourneyStep, DeleteCustomerJourney, DeleteCustomerJourneyStep, GetCustomerJourney, GetCustomerJourneys, UpdateCustomerJourney, UpdateCustomerJourneyStep } from "./customer-journey.action";

type CustomerJourneysStateModel = CustomerJourneyTemplate[];

@State<CustomerJourneysStateModel>({
    name: 'CustomerJourneysState',
    defaults: [],
})
@Injectable()
export class CustomerJourneysState {

    constructor(private service: CustomerJourneyService, private auth: AuthService) { }

    @Selector()
    static getCustomerJourneys(state: CustomerJourneysStateModel) {
        return state;
    }

    @Selector()
    static getCustomerJourneyByIndex(state: CustomerJourneysStateModel) {
        return (id: number) => {
            return state.find((a) => a.id === id);
        };
    }

    @Action(GetCustomerJourneys)
    fetchCustomerJourneys(ctx: StateContext<CustomerJourneysStateModel>, action: GetCustomerJourneys) {
        if (ctx.getState().length > 0) return;

        return this.service.getCustomerJourneys().pipe(
            tap(items => ctx.setState(items))
        );
    }

    @Action(GetCustomerJourney)
    fetchCustomerJourney(ctx: StateContext<CustomerJourneysStateModel>, action: GetCustomerJourney) {
        const state = ctx.getState();
        const eTemplate = state.find(es => es.id == action.payload);

        return eTemplate?.pushNotificationPrompts || eTemplate?.pushNotificationTemplates || eTemplate?.emailTemplates ? eTemplate :
            this.service.getCustomerJourney(action.payload).pipe(
                tap((res) => {
                    const resState = state.map(es => {
                        if (es.id == res.id) {
                            return res;
                        }
                        return es;
                    })
                    ctx.setState(resState);
                })
            );
    }

    @Action(AddCustomerJourney)
    addCustomerJourney(ctx: StateContext<CustomerJourneysStateModel>, action: AddCustomerJourney) {
        const newitem = action.payload;
        const state = ctx.getState();

        return this.service.addCustomerJourney(newitem).pipe(
            tap((res) => {
                ctx.setState([...state, res])
            })
        );
    }

    @Action(UpdateCustomerJourney)
    updateCustomerJourney(ctx: StateContext<CustomerJourneysStateModel>, action: UpdateCustomerJourney) {
        const newitem = action.payload;
        const state = ctx.getState();

        return this.service.updateCustomerJourney(newitem).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(ApproveCustomerJourney)
    approveCustomerJourney(ctx: StateContext<CustomerJourneysStateModel>, action: ApproveCustomerJourney) {
        const state = ctx.getState();

        return this.service.approve(action.payload).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(DeleteCustomerJourney)
    deleteCustomerJourney(ctx: StateContext<CustomerJourneysStateModel>, action: DeleteCustomerJourney) {
        const state = ctx.getState();

        return this.service.deleteCustomerJourney(action.payload).pipe(
            tap(() => {
                const updatedState = state.filter((es: CustomerJourneyTemplate) => es.id !== action.payload);
                ctx.setState([...updatedState]);
            })
        );
    }

    @Action(UpdateCustomerJourneyStep)
    updateCustomerJourneyStep(ctx: StateContext<CustomerJourneysStateModel>, action: UpdateCustomerJourneyStep) {
        const state = ctx.getState();

        return this.service.updateCustomerJourneyStep(action.payload).pipe(tap((res) => {
            const resState = state.map(es => {
                if (es.customerJourneySteps && es.customerJourneySteps.length) {
                    const cjsteps = es.customerJourneySteps.map(cjstep => {
                        return cjstep.id == action.payload.id ?
                            { ...res } : cjstep
                    });
                    es.customerJourneySteps = cjsteps;
                }
                return es;
            });

            ctx.setState(resState);
        }));
    }

    @Action(DeleteCustomerJourneyStep)
    deleteCustomerJourneyStep(ctx: StateContext<CustomerJourneysStateModel>, action: DeleteCustomerJourneyStep) {
        const state = ctx.getState();

        return this.service.deleteStep(action.payload).pipe(
            tap(() => {
                const updatedState = state.map(es => {
                    if (es.customerJourneySteps && es.customerJourneySteps.length) {
                        let cjSteps = es.customerJourneySteps.filter(cjStep => cjStep.id !== action.payload);

                        cjSteps = cjSteps.map((step, index) => ({
                            ...step,
                            sortOrder: index + 1
                        }));

                        es = {
                            ...es,
                            customerJourneySteps: cjSteps
                        };
                    }
                    return es;
                });

                ctx.setState(updatedState);
            })
        );
    }

    @Action(ApproveCustomerJourneyStep)
    approveCustomerJourneyStep(ctx: StateContext<CustomerJourneysStateModel>, action: ApproveCustomerJourneyStep) {
        const state = ctx.getState();

        return this.service.approveStep(action.payload).pipe(tap(() => {
            const resState = state.map(es => {
                if (es.customerJourneySteps && es.customerJourneySteps.length) {
                    const cjsteps = es.customerJourneySteps.map(cjstep => {
                        return cjstep.id == action.payload ?
                            { ...cjstep, approvedById: this.auth.user$.value?.sub, approvedByName: this.auth.user$.value?.name } : cjstep
                    });
                    es.customerJourneySteps = cjsteps;
                }
                return es;
            });

            ctx.setState(resState);
        }));
    }
}
