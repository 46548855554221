import { QueryFilterModel } from "src/app/models/notifications/query-filter";

export class GetQueryFilters {
    static readonly type = '[QueryFilters] Get';
    constructor() { }
}

export class GetQueryFilter {
    static readonly type = '[QueryFilter] Get';
    constructor(public payload: number) { }
}

export class AddQueryFilter {
    static readonly type = '[QueryFilter] Add';
    constructor(public payload: QueryFilterModel) { }
}

export class UpdateQueryFilter {
    static readonly type = '[QueryFilter] Update';
    constructor(public payload: QueryFilterModel) { }
}

export class DeleteQueryFilter {
    static readonly type = '[QueryFilter] Delete';
    constructor(public payload: number) { }
}