import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, inject, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MainRoutesEnum } from '../../enums/main-routes.enum';
import { MainMenuItem } from '../../models/main-menu-item';
import { MainMenuToolbarComponent } from './main-menu-expanded-toolbar/main-menu-toolbar.component';

@Component({
  selector: 'app-main-menu',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MainMenuToolbarComponent,
    MatListModule,
    MatIconModule,
    RouterLink,
  ],
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements AfterViewInit {
  private authService = inject(AuthService);
  private cdr = inject(ChangeDetectorRef);
  user$ = this.authService.user$;
  roles: string[];


  @Output() closeSideNav = new EventEmitter();
  menuList: MainMenuItem[] = [
    {
      id: 'Dashboard',
      label: 'Dashboard',
      icon: 'dashboard',
      route: `/${MainRoutesEnum.landingPage}`,
    } as MainMenuItem,
    {
      id: 'userprofile',
      label: 'Users',
      icon: 'account_box',
      route: `/${MainRoutesEnum.Users}`,
    } as MainMenuItem,
    {
      id: 'localization',
      label: 'Localization',
      icon: 'language',
      route: `/${MainRoutesEnum.localozation}`,
    } as MainMenuItem,
    {
      id: 'Review',
      label: 'Review',
      icon: 'reviews',
      route: `/${MainRoutesEnum.Review}`,
    } as MainMenuItem,
    {
      id: 'Emails',
      label: 'Emails',
      icon: 'mail',
      route: `/${MainRoutesEnum.Emails}`,
    } as MainMenuItem,
    {
      id: 'Blog',
      label: 'Blog',
      icon: 'edit',
      route: `/${MainRoutesEnum.Blog}`,
    } as MainMenuItem,
    {
      id: 'Blog',
      label: 'Posts',
      icon: 'post_add',
      route: `/${MainRoutesEnum.Posts}`,
    } as MainMenuItem,
    {
      id: 'KPI',
      label: 'KPI',
      icon: 'analytics',
      route: `/${MainRoutesEnum.KPI}`,
    } as MainMenuItem,
    {
      id: 'Notifications',
      label: 'Notifications',
      icon: 'notifications',
      route: `/${MainRoutesEnum.Notifications}`,
    } as MainMenuItem,
    {
      id: 'Notifications',
      label: 'NotificationsHub',
      icon: 'hub',
      route: `/${MainRoutesEnum.NotificationHub}`,
    } as MainMenuItem,
    {
      id: 'UserAccess',
      label: 'UserAccess',
      icon: 'key',
      route: `/${MainRoutesEnum.UserAccess}`,
    } as MainMenuItem,
  ];

  selectedPage: MainMenuItem | null = null;
  filteredMenuList: MainMenuItem[];

  constructor(private router: Router) {
    this.authService.loadUser();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectedPage =
          this.menuList.find((item) => item.route === event.url) ?? null;
      }
    });
  }

  isActive(route: string): boolean {
    return this.router.url.includes(route);
  }

  ngAfterViewInit(): void {
    this.user$.subscribe(user => {
      if (!user) {
        this.filteredMenuList = this.menuList;
        return
      }
      const roles = user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (roles && roles.includes('superadmin')) {
        this.filteredMenuList = this.menuList;
      } else {
        roles && roles.length ? this.filteredMenuList = this.menuList.filter((item) => roles.includes(item.id.toLowerCase())) : this.filteredMenuList = [];
      }
      this.cdr.detectChanges();
    });
  }

  closeNav() {
    if (window.innerWidth < 1024) {
      this.closeSideNav.emit();
    }
  }
}
