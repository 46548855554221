import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { tap } from "rxjs";
import { PushNotificationTemplate } from "src/app/models/notifications/notification-templates";
import { NotificationsService } from "src/app/services/notifications.service";
import { AddNotificationTemplate, ApproveNotificationTemplate, DeleteNotificationTemplate, GetNotificationTemplate, GetNotificationTemplates, TranslateNotificationTemplate, UpdateNotificationTemplate } from "./notification-temlate.action";

type NotificationTemplatesStateModel = PushNotificationTemplate[];

@State<NotificationTemplatesStateModel>({
    name: 'NotificationTemplatesState',
    defaults: [],
})
@Injectable()
export class NotificationTemplatesState {

    constructor(private service: NotificationsService, private store: Store) { }

    @Selector()
    static getNotificationsTemplates(state: NotificationTemplatesStateModel) {
        return state;
    }

    @Selector()
    static getTemplateByIndex(state: NotificationTemplatesStateModel) {
        return (id: number) => {
            return state.find((a) => a.id === id);
        };
    }

    @Action(GetNotificationTemplates)
    fetchNotificationsTemplates(ctx: StateContext<NotificationTemplatesStateModel>, action: GetNotificationTemplates) {
        if (ctx.getState().length > 0) return;

        return this.service.getNotificationTemplates().pipe(
            tap(items => ctx.setState(items))
        );
    }

    @Action(GetNotificationTemplate)
    fetchNotificationTemplate(ctx: StateContext<NotificationTemplatesStateModel>, action: GetNotificationTemplate) {
        const state = ctx.getState();

        return this.service.getNotificationTemplate(action.payload).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(AddNotificationTemplate)
    addNotificationTemplate(ctx: StateContext<NotificationTemplatesStateModel>, action: AddNotificationTemplate) {
        const newTemplate = action.payload;
        const state = ctx.getState();

        return this.service.addNotificationTemplate(newTemplate).pipe(
            tap((res) => {
                ctx.setState([...state, res])
            })
        );
    }

    @Action(UpdateNotificationTemplate)
    updateNotificationTemplate(ctx: StateContext<NotificationTemplatesStateModel>, action: UpdateNotificationTemplate) {
        const newTemplate = action.payload;
        const state = ctx.getState();

        return this.service.updateNotificationTemplate(newTemplate).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(TranslateNotificationTemplate)
    translateNotificationTemplate(ctx: StateContext<NotificationTemplatesStateModel>, action: TranslateNotificationTemplate) {
        const newitem = action.payload;
        const state = ctx.getState();

        return this.service.translate(newitem.id).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(ApproveNotificationTemplate)
    approveNotificationTemplate(ctx: StateContext<NotificationTemplatesStateModel>, action: ApproveNotificationTemplate) {
        const state = ctx.getState();

        return this.service.approved(action.payload).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(DeleteNotificationTemplate)
    deleteNotificationTemplate(ctx: StateContext<NotificationTemplatesStateModel>, action: DeleteNotificationTemplate) {
        const state = ctx.getState();

        return this.service.deleteNotificationTemplate(action.payload).pipe(
            tap(() => {
                const updatedState = state.filter((es: PushNotificationTemplate) => es.id !== action.payload);
                ctx.setState([...updatedState]);
            })
        );
    }
}
