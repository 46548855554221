import { inject } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { roleGuardFactory } from './auth/roles.guard';
import { AuthService } from './auth/services/auth.service';
import { FlatViewResolver } from './components/localization/flat-view.resolver';
import { PromptsResolver } from './components/notifications/components/prompts/prompts.resolver';
import { NotificationTemplatesResolver } from './components/notifications/notification-template.resolver';
import { UserAccessResolver } from './components/user-access/user-access.resolver';
import { PointSystemResolver } from './components/users/user-level-settings/point-system.resolver';
import { UserLevelsResolver } from './components/users/user-level-settings/user-levels.resolver';
import { MainRoutesEnum } from './enums/main-routes.enum';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [
      async () => {
        const router = inject(Router);
        const authService = inject(AuthService);
        if (authService.isAuthenticated()) {
          return router.createUrlTree(['/' + MainRoutesEnum.landingPage], {
            queryParams: {},
          });
        }
        const isCodeAvailiable = authService.checkIfCodeAvailiable(
          window.location.search
        );
        if (isCodeAvailiable) {
          const loggedIn = await authService.codeFlowLoginPromise();

          if (loggedIn && authService.isAuthenticated()) {
            return router.createUrlTree([authService.requestedUrl], {
              queryParams: {},
            });
          }
        } else {
          authService.loginMicrosoft().then();
          return false;
        }
        return false;
      },
    ],
    loadComponent: () =>
      import('./auth/components/login/login/login.component').then(
        (c) => c.LoginComponent
      ),
  },
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: MainRoutesEnum.landingPage,
        loadComponent: () =>
          import('./components/landing-page/landing-page.component').then(
            (c) => c.LandingPageComponent
          ),
      },
      {
        path: MainRoutesEnum.localozation,
        canActivate: [roleGuardFactory(['localization', 'superadmin'])],
        resolve: { localizationDtos: FlatViewResolver },
        loadChildren: () =>
          import('./components/localization/localization.routing').then(
            (c) => c.LocalizationRoutes
          ),
      },
      {
        path: MainRoutesEnum.Users,
        canActivate: [roleGuardFactory(['userprofile', 'superadmin'])],
        resolve: { pointSystemItems: PointSystemResolver, userLevels: UserLevelsResolver, prompts: PromptsResolver },
        loadChildren: () =>
          import('./components/users/users.routes').then(
            (m) => m.USERS_ROUTES
          ),
      },
      {
        path: MainRoutesEnum.localozationLanguageApproval,
        canActivate: [roleGuardFactory(['localization', 'superadmin'])],
        resolve: { languages: FlatViewResolver },
        loadComponent: () =>
          import('./components/localization/localization-language-approval/localization-language-approval.component').then(
            (c) => c.LocalizationLanguageApprovalComponent
          ),
      },
      {
        path: MainRoutesEnum.Review,
        canActivate: [roleGuardFactory(['review', 'superadmin'])],
        loadComponent: () =>
          import('./components/review/review.component').then(
            (c) => c.ReviewComponent
          ),
      },
      {
        path: MainRoutesEnum.Blog,
        canActivate: [roleGuardFactory(['blog', 'superadmin'])],
        loadComponent: () =>
          import('./components/blog/blog/blog.component').then(
            (c) => c.BlogComponent
          ),
      },
      {
        path: MainRoutesEnum.Posts,
        canActivate: [roleGuardFactory(['blog', 'superadmin'])],
        loadComponent: () =>
          import('./components/posts/posts.component').then(
            (c) => c.PostsComponent
          ),
      },
      {
        path: MainRoutesEnum.Emails,
        canActivate: [roleGuardFactory(['emails', 'superadmin'])],
        loadChildren: () =>
          import('./components/emails/emails.routes').then(
            (c) => c.EMAILS_ROUTES
          ),
      },
      {
        path: MainRoutesEnum.KPI,
        canActivate: [roleGuardFactory(['kpi', 'superadmin'])],
        loadComponent: () =>
          import('./components/kpi/kpi.component').then(
            (c) => c.KpiComponent
          ),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'values',
          },
          {
            path: 'values',
            loadComponent: () =>
              import('./components/kpi/components/kpi-values/kpi-values.component').then(
                (c) => c.KpiValuesComponent
              ),
          },
          {
            path: 'user-values',
            loadComponent: () =>
              import('./components/kpi/components/kpi-user-values/kpi-user-values.component').then(
                (c) => c.KpiUserValuesComponent
              ),
          }]
      },
      {
        path: MainRoutesEnum.Notifications,
        canActivate: [roleGuardFactory(['notifications', 'superadmin'])],
        loadComponent: () =>
          import('./components/notifications/notifications.component').then(
            (c) => c.NotificationsComponent
          ),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'types',
          },
          {
            path: 'types',
            loadComponent: () =>
              import('./components/notifications/components/notification-types/notification-types.component').then(
                (c) => c.NotificationTypesComponent
              ),
          },
          {
            path: 'log',
            loadComponent: () =>
              import('./components/notifications/components/notifications-list/notifications-list.component').then(
                (c) => c.NotificationsListComponent
              ),
          },
          {
            path: 'prompts',
            resolve: { prompts: PromptsResolver },
            loadComponent: () =>
              import('./components/notifications/components/prompts/prompts.component').then(
                (c) => c.PromptsComponent
              ),
          },
          {
            path: 'notification-templates',
            resolve: { notificationtemplates: NotificationTemplatesResolver },
            loadComponent: () =>
              import('./components/notifications/components/notification-templates/notification-templates.component').then(
                (c) => c.NotificationTemplatesComponent
              ),
          },]
      },
      {
        path: MainRoutesEnum.NotificationHub,
        canActivate: [roleGuardFactory(['notifications', 'superadmin'])],
        loadChildren: () =>
          import('./components/notification-hub/notification-hub.routes').then(
            (c) => c.NotificationHub_ROUTES
          ),
      },
    ],
  },
  {
    path: MainRoutesEnum.Screenshots + '/:entId',
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/localization/localization-screenshots-list/localization-screenshots-list.component').then(
        (c) => c.LocalizationScreenshotsListComponent
      ),
  },
  {
    path: MainRoutesEnum.UserAccess,
    resolve: { userswithroles: UserAccessResolver },
    canActivate: [roleGuardFactory(['superadmin'])],
    loadComponent: () =>
      import('./components/user-access/user-access.component').then(
        (c) => c.UserAccessComponent
      ),
  },
  {
    path: 'forbidden',
    loadComponent: () =>
      import('./components/forbidden/forbidden.component').then(
        (c) => c.ForbiddenComponent
      ),
  },
];
