import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EmailTemplate } from "../models/email";

@Injectable({ providedIn: 'root' })
export class EmailTemplatesService {
    private http = inject(HttpClient);

    private readonly url: string = `${environment.api.url}email-templates/templates`;

    public getEmailTemplates(
    ): Observable<EmailTemplate[]> {
        return this.http.get<EmailTemplate[]>(this.url);
    }

    public getEmailTemplate(id: number): Observable<EmailTemplate> {
        return this.http.get<EmailTemplate>(`${this.url}/${id}`);
    }

    public addEmailTemplate(etemplate: EmailTemplate): Observable<EmailTemplate> {
        return this.http.post<EmailTemplate>(this.url, etemplate);
    }
    public updateEmailTemplate(etemplate: EmailTemplate): Observable<EmailTemplate> {
        return this.http.put<EmailTemplate>(this.url, etemplate);
    }

    public deleteEmailTemplate(etemplateid: number) {
        return this.http.delete(`${this.url}/${etemplateid}`);
    }

    public sendEmeilToUser(email: string, id: number, languageCode: string) {
        return this.http.post(`${this.url}/${id}/send/${email}/${languageCode}`, {});
    }

    public translate(id: number): Observable<EmailTemplate> {
        return this.http.post<EmailTemplate>(`${this.url}/${id}/translate`, {});
    }
}