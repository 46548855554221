import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport,
} from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsModule } from "@ngxs/store";
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc';
import { provideToastr, ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { AuthInterceptorService } from './auth/services/auth-interceptor.service';
import { CustomerJourneysState } from './store/customer-journey/customer-journey.state';
import { EmailSectionsState } from './store/email-section/email.section.state';
import { EmailTemplatesState } from './store/email-template/email-template.state';
import { GoogleAnalyticsState } from "./store/google-analytics/google-analytics.state";
import { LocalizationState } from './store/localization/localization.state';
import { NotificationTemplatesState } from './store/notification-template/notification-template.state';
import { OnboardingState } from './store/onboarding/onboarding.state';
import { PointSystemState } from './store/point-system/point-system.state';
import { PromptState } from './store/prompt/prompt.state';
import { QuerieFilterState } from './store/query-filters/query-filters.state';
import { SoundsState } from './store/sounds/sounds.state';
import { UserAccessState } from './store/user-access/user-access.state';
import { UserLevelState } from './store/user-level/user-level.state';


export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withJsonpSupport(), withInterceptorsFromDi()),
    provideRouter(routes),
    provideAnimations(),
    importProvidersFrom(
      BrowserAnimationsModule,
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      }),
      NgxsModule.forRoot([
        GoogleAnalyticsState,
        UserAccessState,
        LocalizationState,
        PointSystemState,
        UserLevelState,
        PromptState,
        EmailSectionsState,
        EmailTemplatesState,
        NotificationTemplatesState,
        QuerieFilterState,
        CustomerJourneysState,
        OnboardingState,
        SoundsState,
      ]),
      NgxsReduxDevtoolsPluginModule.forRoot(),
      ToastrModule.forRoot({
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
        closeButton: true,
      }),
      AngularFireModule.initializeApp(environment.firebase)
    ),
    provideAnimations(), // required animations providers
    provideToastr(), // Toastr providers
    provideOAuthClient({
      resourceServer: {
        allowedUrls: [environment.api.url],
        sendAccessToken: true,
      },
    }),
    { provide: OAuthStorage, useFactory: () => localStorage },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
};
