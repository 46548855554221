import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs";
import { PromptModel } from "src/app/models/notifications/prompt.model";
import { PromptService } from "src/app/services/prompt.service";
import { AddPrompt, DeletePrompt, GetPrompts, UpdatePrompt } from "./prompt.action";

type PromptStateModel = PromptModel[];

@State<PromptStateModel>({
    name: 'PromptState',
    defaults: [],
})
@Injectable()
export class PromptState {

    constructor(private promptService: PromptService) { }

    @Selector()
    static getprompts(state: PromptStateModel) {
        return state;
    }

    @Action(GetPrompts)
    fetchPrompts(ctx: StateContext<PromptStateModel>, action: GetPrompts) {

        return this.promptService.getPrompts(action.payload.sortBy, action.payload.sortDirection).pipe(
            tap(items => ctx.setState(items))
        );
    }

    @Action(AddPrompt)
    addPrompt(ctx: StateContext<PromptStateModel>, action: AddPrompt) {
        const state = ctx.getState();

        return this.promptService.addPrompt(action.payload).pipe(
            tap((res) => {
                ctx.setState([...state, res])
            })
        );
    }

    @Action(UpdatePrompt)
    updatePrompt(ctx: StateContext<PromptStateModel>, action: UpdatePrompt) {
        const newitem = action.payload;
        const state = ctx.getState();

        return this.promptService.updatePrompt(newitem).pipe(
            tap((res) => {
                const resState = state.map(pm => {
                    if (pm.id == res.id) {
                        return res;
                    }
                    return pm;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(DeletePrompt)
    deletePrompt(ctx: StateContext<PromptStateModel>, action: DeletePrompt) {
        const state = ctx.getState();

        return this.promptService.deletePrompt(action.payload).pipe(
            tap(() => {
                const updatedState = state.filter((pm: PromptModel) => pm.id !== action.payload);
                ctx.setState([...updatedState]);
            })
        );
    }
}
