import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { of, tap } from "rxjs";
import { NotificationTemplate } from "src/app/models/notifications/notification-templates";
import { NotificationsService } from "src/app/services/notifications.service";
import { AddNotificationTemplate, DeleteNotificationTemplate, GetNotificationTemplate, GetNotificationTemplates, TranslateNotificationTemplate, UpdateNotificationTemplate } from "./notification-temlate.action";

// Sample notification templates mock data
const mockNotificationTemplates: NotificationTemplate[] = [
    {
        id: 1,
        name: 'Welcome Email',
        content: 'Welcome to our platform!',
        subject: 'Welcome!',
        updatedDate: new Date('2024-10-01'),
        aprovedByUserId: '123',
        aprovedByUserName: 'Admin User',
        translatedNotificationTemplates: [
            { id: 101, language: 'en', content: 'Welcome to our platform!', subject: 'Welcome!' },
            { id: 102, language: 'es', content: '¡Bienvenido a nuestra plataforma!', subject: '¡Bienvenido!' },
            { id: 103, language: 'fr', content: 'Bienvenue sur notre plateforme!', subject: 'Bienvenue!' },
            { id: 104, language: 'de', content: 'Willkommen auf unserer Plattform!', subject: 'Willkommen!' },
            { id: 105, language: 'it', content: 'Benvenuto sulla nostra piattaforma!', subject: 'Benvenuto!' },
            { id: 106, language: 'pt', content: 'Bem-vindo à nossa plataforma!', subject: 'Bem-vindo!' },
            { id: 107, language: 'ru', content: 'Добро пожаловать на нашу платформу!', subject: 'Добро пожаловать!' },
            { id: 108, language: 'zh', content: '欢迎来到我们的平台！', subject: '欢迎！' },
            { id: 109, language: 'ja', content: '私たちのプラットフォームへようこそ！', subject: 'ようこそ！' }
        ]
    },
    {
        id: 2,
        name: 'Password Reset',
        content: 'Click the link below to reset your password.',
        subject: 'Password Reset Request',
        updatedDate: new Date('2024-11-01'),
        aprovedByUserId: '124',
        aprovedByUserName: 'Support User',
        translatedNotificationTemplates: [
            { id: 110, language: 'en', content: 'Click the link below to reset your password.', subject: 'Password Reset Request' },
            { id: 111, language: 'es', content: 'Haz clic en el enlace de abajo para restablecer tu contraseña.', subject: 'Solicitud de restablecimiento de contraseña' },
            { id: 112, language: 'fr', content: 'Cliquez sur le lien ci-dessous pour réinitialiser votre mot de passe.', subject: 'Demande de réinitialisation du mot de passe' },
            { id: 113, language: 'de', content: 'Klicken Sie auf den unten stehenden Link, um Ihr Passwort zurückzusetzen.', subject: 'Passwort zurücksetzen Anfrage' },
            { id: 114, language: 'it', content: 'Fai clic sul link qui sotto per reimpostare la tua password.', subject: 'Richiesta di reimpostazione della password' },
            { id: 115, language: 'pt', content: 'Clique no link abaixo para redefinir sua senha.', subject: 'Solicitação de redefinição de senha' },
            { id: 116, language: 'ru', content: 'Нажмите на ссылку ниже, чтобы сбросить пароль.', subject: 'Запрос на сброс пароля' },
            { id: 117, language: 'zh', content: '点击下面的链接重置密码。', subject: '密码重置请求' },
            { id: 118, language: 'ja', content: '下のリンクをクリックしてパスワードをリセットしてください。', subject: 'パスワードリセットリクエスト' }
        ]
    },
    {
        id: 3,
        name: 'Account Deactivation',
        content: 'Your account has been deactivated due to inactivity.',
        subject: 'Account Deactivation Notice',
        updatedDate: new Date('2024-11-05'),
        aprovedByUserId: '125',
        aprovedByUserName: 'Security Admin',
        translatedNotificationTemplates: [
            { id: 119, language: 'en', content: 'Your account has been deactivated due to inactivity.', subject: 'Account Deactivation Notice' },
            { id: 120, language: 'es', content: 'Tu cuenta ha sido desactivada debido a inactividad.', subject: 'Aviso de desactivación de cuenta' },
            { id: 121, language: 'fr', content: 'Votre compte a été désactivé en raison d\'inactivité.', subject: 'Avis de désactivation de compte' },
            { id: 122, language: 'de', content: 'Ihr Konto wurde aufgrund von Inaktivität deaktiviert.', subject: 'Benachrichtigung über die Deaktivierung des Kontos' },
            { id: 123, language: 'it', content: 'Il tuo account è stato disattivato a causa dell\'inattività.', subject: 'Avviso di disattivazione dell\'account' },
            { id: 124, language: 'pt', content: 'Sua conta foi desativada devido à inatividade.', subject: 'Aviso de desativação de conta' },
            { id: 125, language: 'ru', content: 'Ваш аккаунт был деактивирован из-за неактивности.', subject: 'Уведомление о деактивации учетной записи' },
            { id: 126, language: 'zh', content: '由于不活动，您的帐户已被停用。', subject: '账户停用通知' },
            { id: 127, language: 'ja', content: 'アカウントが非アクティブのため無効になりました。', subject: 'アカウント無効化通知' }
        ]
    },
    {
        id: 4,
        name: 'Subscription Expiry',
        content: 'Your subscription will expire soon. Please renew to continue using our services.',
        subject: 'Subscription Expiry Reminder',
        updatedDate: new Date('2024-11-10'),
        aprovedByUserId: '126',
        aprovedByUserName: 'Billing Admin',
        translatedNotificationTemplates: [
            { id: 128, language: 'en', content: 'Your subscription will expire soon. Please renew to continue using our services.', subject: 'Subscription Expiry Reminder' },
            { id: 129, language: 'es', content: 'Tu suscripción expirará pronto. Por favor, renueva para continuar usando nuestros servicios.', subject: 'Recordatorio de expiración de suscripción' },
            { id: 130, language: 'fr', content: 'Votre abonnement va bientôt expirer. Veuillez renouveler pour continuer à utiliser nos services.', subject: 'Rappel d\'expiration de l\'abonnement' },
            { id: 131, language: 'de', content: 'Ihr Abonnement läuft bald ab. Bitte verlängern Sie, um unsere Dienste weiterhin nutzen zu können.', subject: 'Erinnerung an das Abonnementablauf' },
            { id: 132, language: 'it', content: 'Il tuo abbonamento scadrà presto. Si prega di rinnovare per continuare a utilizzare i nostri servizi.', subject: 'Promemoria scadenza abbonamento' },
            { id: 133, language: 'pt', content: 'Sua assinatura expirará em breve. Renove para continuar a usar nossos serviços.', subject: 'Lembrete de expiração da assinatura' },
            { id: 134, language: 'ru', content: 'Срок действия вашей подписки скоро истечет. Пожалуйста, продлите, чтобы продолжить пользоваться нашими услугами.', subject: 'Напоминание об истечении подписки' },
            { id: 135, language: 'zh', content: '您的订阅即将到期。请续订以继续使用我们的服务。', subject: '订阅到期提醒' },
            { id: 136, language: 'ja', content: 'サブスクリプションがまもなく期限切れになります。引き続きサービスをご利用いただくには、更新してください。', subject: 'サブスクリプション期限切れリマインダー' }
        ]
    }
];


type NotificationTemplatesStateModel = NotificationTemplate[];

@State<NotificationTemplatesStateModel>({
    name: 'NotificationTemplatesState',
    defaults: [],
})
@Injectable()
export class NotificationTemplatesState {

    constructor(private service: NotificationsService, private store: Store) { }

    @Selector()
    static getNotificationsTemplates(state: NotificationTemplatesStateModel) {
        return state;
    }

    @Selector()
    static getTemplateByIndex(state: NotificationTemplatesStateModel) {
        return (id: number) => {
            return state.find((a) => a.id === id);
        };
    }

    @Action(GetNotificationTemplates)
    fetchNotificationsTemplates(ctx: StateContext<NotificationTemplatesStateModel>, action: GetNotificationTemplates) {
        if (ctx.getState().length > 0) return;

        return ctx.setState([...mockNotificationTemplates]);
        // return this.service.getNotificationTemplates().pipe(
        //     tap(items => ctx.setState(items))
        // );
    }

    @Action(GetNotificationTemplate)
    fetchNotificationTemplate(ctx: StateContext<NotificationTemplatesStateModel>, action: GetNotificationTemplate) {
        const state = ctx.getState();
        const nTemplate = state.find(es => es.id == action.payload);
        if (nTemplate?.translatedNotificationTemplates && nTemplate?.translatedNotificationTemplates.length > 0) return

        const template = mockNotificationTemplates.find(t => t.id === action.payload);
        return of(template);

        return this.service.getNotificationTemplate(action.payload).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(AddNotificationTemplate)
    addNotificationTemplate(ctx: StateContext<NotificationTemplatesStateModel>, action: AddNotificationTemplate) {
        const newTemplate = action.payload;
        const state = ctx.getState();

        newTemplate.id = mockNotificationTemplates.length + 1;
        newTemplate.updatedDate = new Date();
        return ctx.setState([...state, newTemplate])

        return this.service.addNotificationTemplate(newTemplate).pipe(
            tap((res) => {
                ctx.setState([...state, res])
            })
        );
    }

    @Action(UpdateNotificationTemplate)
    updateNotificationTemplate(ctx: StateContext<NotificationTemplatesStateModel>, action: UpdateNotificationTemplate) {
        const newTemplate = action.payload;
        const state = ctx.getState();

        const template = mockNotificationTemplates.find(t => t.id === action.payload.id);
        if (template) {
            return of(template);
        }
        return of(null);

        return this.service.updateNotificationTemplate(newTemplate).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(TranslateNotificationTemplate)
    translateNotificationTemplate(ctx: StateContext<NotificationTemplatesStateModel>, action: TranslateNotificationTemplate) {
        const newitem = action.payload;
        const state = ctx.getState();

        const template = mockNotificationTemplates.find(t => t.id === action.payload.id);
        if (template) {
            return of(template);
        }
        return of(null);

        return this.service.translate(newitem.id).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(DeleteNotificationTemplate)
    deleteNotificationTemplate(ctx: StateContext<NotificationTemplatesStateModel>, action: DeleteNotificationTemplate) {
        const state = ctx.getState();

        const updatedState = state.filter((es: NotificationTemplate) => es.id !== action.payload);
        return ctx.setState([...updatedState]);

        return this.service.deleteNotificationTemplate(action.payload).pipe(
            tap(() => {
                const updatedState = state.filter((es: NotificationTemplate) => es.id !== action.payload);
                ctx.setState([...updatedState]);
            })
        );
    }
}
