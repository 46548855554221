import { OnboardingItem, OnboardingStep } from "src/app/models/onboarding.model";

export class GetOnboarding {
    static readonly type = '[OnboardingItems] Get';
    constructor() { }
}

export class GetOnboardingItem {
    static readonly type = '[OnboardingItem] Get';
    constructor(public payload: number) { }
}

export class AddOnboardingItem {
    static readonly type = '[OnboardingItem] Add';
    constructor(public payload: OnboardingItem) { }
}

export class UpdateOnboardingItem {
    static readonly type = '[OnboardingItem] Update';
    constructor(public payload: OnboardingItem) { }
}

export class TranslateEmailTemplate {
    static readonly type = '[OnboardingItem] Translate';
    constructor(public payload: OnboardingItem) { }
}

export class ApproveOnboardingItem {
    static readonly type = '[OnboardingItem] Approve';
    constructor(public payload: number) { }
}

export class DeleteOnboardingItem {
    static readonly type = '[OnboardingItem] Delete';
    constructor(public payload: number) { }
}

export class AddOnboardingStep {
    static readonly type = '[OnboardingStep] Add';
    constructor(public payload: OnboardingStep) { }
}

export class UpdateOnboardingStep {
    static readonly type = '[OnboardingStep] Update';
    constructor(public payload: OnboardingStep) { }
}

export class DeleteOnboardingStep {
    static readonly type = '[OnboardingStep] Delete';
    constructor(public payload: number) { }
}
