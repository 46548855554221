import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { OnboardingItem, OnboardingStep } from "../models/onboarding.model";

@Injectable({ providedIn: 'root' })
export class OnboardingService {
    private http = inject(HttpClient);

    private readonly url: string = `${environment.api.url}onboarding`;

    public getOnboardingItems(): Observable<OnboardingItem[]> {
        return this.http.get<OnboardingItem[]>(this.url);
    }

    public getOnboardingItem(id: number): Observable<OnboardingItem> {
        return this.http.get<OnboardingItem>(`${this.url}/${id}`);
    }

    public addOnboardingItem(item: OnboardingItem): Observable<OnboardingItem> {
        return this.http.post<OnboardingItem>(`${this.url}`, item);
    }

    public updateOnboardingItem(item: OnboardingItem): Observable<OnboardingItem> {
        return this.http.put<OnboardingItem>(this.url, item);
    }

    public deleteOnboardingItem(id: number) {
        return this.http.delete(`${this.url}/${id}`);
    }

    public approve(id: number): Observable<OnboardingItem> {
        return this.http.post<OnboardingItem>(`${this.url}/${id}/approve`, {});
    }

    // steps apis

    public addStep(stepItem: OnboardingStep): Observable<OnboardingStep> {
        return this.http.post<OnboardingStep>(`${this.url}/step`, stepItem);
    }

    public deleteStep(id: number) {
        return this.http.delete(`${this.url}/step/${id}`);
    }

    public updateStep(step: OnboardingStep): Observable<OnboardingStep> {
        return this.http.put<OnboardingStep>(`${this.url}/step/${step.id}`, step);
    }
}
