import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EmailSection } from "../models/email";


@Injectable({ providedIn: 'root' })
export class EmailSectionService {
    private http = inject(HttpClient);

    private readonly url: string = `${environment.api.url}email-templates/sections`;

    public getEmailSections(
    ): Observable<EmailSection[]> {
        return this.http.get<EmailSection[]>(this.url);
    }

    public getEmailSection(id: number): Observable<EmailSection> {
        return this.http.get<EmailSection>(`${this.url}/${id}`);
    }

    public addEmailSection(esection: EmailSection): Observable<EmailSection> {
        return this.http.post<EmailSection>(this.url, esection);
    }
    public updateEmailSection(esection: EmailSection): Observable<EmailSection> {
        return this.http.put<EmailSection>(this.url, esection);
    }

    public deleteEmailSection(esectionid: number) {
        return this.http.delete(`${this.url}/${esectionid}`);
    }
}
