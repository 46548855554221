import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { tap } from "rxjs";
import { PromptModel } from "src/app/models/notifications/prompt.model";
import { PromptService } from "src/app/services/prompt.service";
import { AddPrompt, DeletePrompt, GetPrompts, UpdatePrompt } from "./prompt.action";

type PromptStateModel = PromptModel[];

@State<PromptStateModel>({
    name: 'PromptState',
    defaults: [],
})
@Injectable()
export class PromptState {

    constructor(private promptService: PromptService, private store: Store) { }

    @Selector()
    static getprompts(state: PromptStateModel) {
        return state;
    }

    @Action(GetPrompts)
    fetchPrompts(ctx: StateContext<PromptStateModel>, action: GetPrompts) {
        return this.promptService.getPrompts(action.payload.sortBy, action.payload.sortDirection).pipe(
            tap(items => ctx.setState(items))
        );
    }

    @Action(AddPrompt)
    addPrompt(ctx: StateContext<PromptStateModel>, action: AddPrompt) {
        const newitem = action.payload;

        return this.promptService.addPrompt(newitem).pipe(
            tap(() => {
                // Dispatch GetPrompts after adding a prompt to refresh the state
                this.store.dispatch(new GetPrompts({ sortBy: 'id', sortDirection: 'asc' }));
            })
        );
    }

    @Action(UpdatePrompt)
    updatePrompt(ctx: StateContext<PromptStateModel>, action: UpdatePrompt) {
        const newitem = action.payload;

        return this.promptService.updatePrompt(newitem).pipe(
            tap(() => {
                // Dispatch GetPrompts after updating a prompt to refresh the state
                this.store.dispatch(new GetPrompts({ sortBy: 'id', sortDirection: 'asc' }));
            })
        );
    }

    @Action(DeletePrompt)
    deletePrompt(ctx: StateContext<PromptStateModel>, action: DeletePrompt) {

        return this.promptService.deletePrompt(action.payload).pipe(
            tap(() => {
                // Dispatch GetPrompts after updating a prompt to refresh the state
                this.store.dispatch(new GetPrompts({ sortBy: 'id', sortDirection: 'asc' }));
            })
        );
    }
}
