import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs";
import { QueryFilterModel } from "src/app/models/notifications/query-filter";
import { QueriesService } from "src/app/services/queries.service";
import { AddQueryFilter, DeleteQueryFilter, GetQueryFilter, GetQueryFilters, UpdateQueryFilter } from "./query-filters.action";

type QuerieFiltersStateModel = QueryFilterModel[];

@State<QuerieFiltersStateModel>({
    name: 'QueryFiltersState',
    defaults: [],
})
@Injectable()
export class QuerieFilterState {

    constructor(private service: QueriesService) { }

    @Selector()
    static getQueries(state: QuerieFiltersStateModel) {
        return state;
    }

    @Selector()
    static getQueryByIndex(state: QuerieFiltersStateModel) {
        return (id: number) => {
            return state.find((a) => a.id === id);
        };
    }

    @Action(GetQueryFilters)
    fetchQueries(ctx: StateContext<QuerieFiltersStateModel>, action: GetQueryFilters) {
        return this.service.getQueries().pipe(
            tap(items => ctx.setState(items))
        );
    }

    @Action(GetQueryFilter)
    getQueryFilter(ctx: StateContext<QuerieFiltersStateModel>, action: GetQueryFilter) {
        const state = ctx.getState();

        return this.service.getQueryFilter(action.payload).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(AddQueryFilter)
    addPrompt(ctx: StateContext<QuerieFiltersStateModel>, action: AddQueryFilter) {
        const state = ctx.getState();

        return this.service.addQueryFilter(action.payload).pipe(
            tap((res) => {
                ctx.setState([...state, res])
            })
        );
    }

    @Action(UpdateQueryFilter)
    updateQueryFilter(ctx: StateContext<QuerieFiltersStateModel>, action: UpdateQueryFilter) {
        const newitem = action.payload;
        const state = ctx.getState();

        return this.service.updateQueryFilter(newitem).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(DeleteQueryFilter)
    deleteQueryFilter(ctx: StateContext<QuerieFiltersStateModel>, action: DeleteQueryFilter) {
        const state = ctx.getState();

        return this.service.deleteQueryFilter(action.payload).pipe(
            tap(() => {
                const updatedState = state.filter((es: QueryFilterModel) => es.id !== action.payload);
                ctx.setState([...updatedState]);
            })
        );
    }
}
