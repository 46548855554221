import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { PromptModel } from "../models/notifications/prompt.model";


@Injectable({ providedIn: 'root' })
export class PromptService {
    private http = inject(HttpClient);

    private readonly url: string = `${environment.api.url}prompts`;

    public getPrompts(
        sortBy: string,
        sortDirection: string,
    ): Observable<PromptModel[]> {
        let params = new HttpParams()
            .set('sortBy', sortBy)
            .set('isSortAscending', sortDirection === 'asc' ? true : false);

        return this.http.get<any>(this.url, { params });
    }

    public addPrompt(prompt: PromptModel): Observable<PromptModel> {
        return this.http.post<PromptModel>(this.url, prompt);
    }
    public updatePrompt(prompt: PromptModel): Observable<PromptModel> {
        return this.http.put<PromptModel>(this.url, prompt);
    }

    public deletePrompt(promptid: number) {
        return this.http.delete(`${this.url}/${promptid}`);
    }
}
