import { EmailTemplate } from "src/app/models/email";

export class GetEmailTemplates {
    static readonly type = '[EmailTemplates] Get';
    constructor() { }
}

export class GetEmailTemplate {
    static readonly type = '[EmailTemplate] Get';
    constructor(public payload: number) { }
}

export class AddEmailTemplate {
    static readonly type = '[EmailTemplate] Add';
    constructor(public payload: EmailTemplate) { }
}

export class UpdateEmailTemplate {
    static readonly type = '[EmailTemplate] Update';
    constructor(public payload: EmailTemplate) { }
}

export class TranslateEmailTemplate {
    static readonly type = '[EmailTemplate] Translate';
    constructor(public payload: EmailTemplate) { }
}

export class ApproveEmailTemplate {
    static readonly type = '[EmailTemplate] Approve';
    constructor(public payload: number) { }
}

export class DeleteEmailTemplate {
    static readonly type = '[EmailTemplate] Delete';
    constructor(public payload: number) { }
}