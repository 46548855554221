import { SoundModel } from "src/app/models/sound-model";

export class GetSounds {
    static readonly type = '[Sounds] Get';
    constructor() { }
}

export class AddSound {
    static readonly type = '[Sound] Add';
    constructor(public payload: SoundModel) { }
}

export class DeleteSound {
    static readonly type = '[Sound] Delete';
    constructor(public payload: number) { }
}
