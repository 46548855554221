import { EmailSection } from "src/app/models/email";

export class GetEmailSections {
    static readonly type = '[EmailSections] Get';
    constructor() { }
}

export class GetEmailSection {
    static readonly type = '[EmailSection] Get';
    constructor(public payload: number) { }
}

export class AddEmailSection {
    static readonly type = '[EmailSection] Add';
    constructor(public payload: EmailSection) { }
}

export class UpdateEmailSection {
    static readonly type = '[EmailSection] Update';
    constructor(public payload: EmailSection) { }
}

export class DeleteEmailSection {
    static readonly type = '[EmailSection] Delete';
    constructor(public payload: number) { }
}