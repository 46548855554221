import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { NotificationTemplate } from "../models/notifications/notification-templates";
import { NotificationModel } from "../models/notifications/notification.model";

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  private http = inject(HttpClient);

  private readonly url: string = `${environment.api.url}notifications`;

  public getNotificationTypes(): Observable<any> {
    return this.http.get<any>(this.url + '/GetNotificationTypes');
  }

  public getNotifications(page: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    fromDate: Date | null,
    toDate: Date | null): Observable<any> {

    let params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('isSortAscending', sortDirection === 'asc' ? true : false)
      .set('fromDate', fromDate?.toDateString() ? fromDate?.toDateString() : '')
      .set('toDate', toDate?.toDateString() ? toDate?.toDateString() : '');

    return this.http.get<any>(this.url + '/GetNotifications', { params });
  }

  public getUserNotifications(userId: string): Observable<NotificationModel[]> {
    return this.http.get<NotificationModel[]>(`${this.url}/GetNotifications/${userId}`);
  }

  public createNotification(userId: string, header: string, message: string, originalHeader?: string, originalMessage?: string, image?: string): Observable<NotificationModel> {
    return this.http.post<NotificationModel>(`${this.url}/CreateNotification`, { userId, header, message, originalHeader, originalMessage, image });
  }

  //NOTIFICATION-tEMPLATES

  private readonly notificationurl: string = `${environment.api.url}notification-templates`;

  public getNotificationTemplates(
  ): Observable<NotificationTemplate[]> {
    return this.http.get<NotificationTemplate[]>(this.notificationurl);
  }

  public getNotificationTemplate(id: number): Observable<NotificationTemplate> {
    return this.http.get<NotificationTemplate>(`${this.notificationurl}/${id}`);
  }

  public addNotificationTemplate(ntemplate: NotificationTemplate): Observable<NotificationTemplate> {
    return this.http.post<NotificationTemplate>(this.notificationurl, ntemplate);
  }
  public updateNotificationTemplate(ntemplate: NotificationTemplate): Observable<NotificationTemplate> {
    return this.http.put<NotificationTemplate>(this.notificationurl, ntemplate);
  }

  public deleteNotificationTemplate(ntemplateid: number) {
    return this.http.delete(`${this.notificationurl}/${ntemplateid}`);
  }

  public sendPushNotificationToUser(notification: string, id: number, languageCode: string) {
    return this.http.post(`${this.notificationurl}/${id}/send/${notification}/${languageCode}`, {});
  }

  public translate(id: number): Observable<NotificationTemplate> {
    return this.http.post<NotificationTemplate>(`${this.notificationurl}/${id}/translate`, {});
  }
}
