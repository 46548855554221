import { PushNotificationTemplate } from "src/app/models/notifications/notification-templates";

export class GetNotificationTemplates {
    static readonly type = '[NotificationTemplates] Get';
    constructor() { }
}

export class GetNotificationTemplate {
    static readonly type = '[NotificationTemplate] Get';
    constructor(public payload: number) { }
}

export class AddNotificationTemplate {
    static readonly type = '[NotificationTemplate] Add';
    constructor(public payload: PushNotificationTemplate) { }
}

export class UpdateNotificationTemplate {
    static readonly type = '[NotificationTemplate] Update';
    constructor(public payload: PushNotificationTemplate) { }
}

export class TranslateNotificationTemplate {
    static readonly type = '[NotificationTemplate] Translate';
    constructor(public payload: PushNotificationTemplate) { }
}

export class ApproveNotificationTemplate {
    static readonly type = '[NotificationTemplate] Approve';
    constructor(public payload: number) { }
}

export class DeleteNotificationTemplate {
    static readonly type = '[NotificationTemplate] Delete';
    constructor(public payload: number) { }
}