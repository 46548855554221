import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SoundModel } from "../models/sound-model";

@Injectable({ providedIn: 'root' })
export class SoundsService {
    private http = inject(HttpClient);

    private readonly url: string = `${environment.api.url}sounds`;

    public getSounds(): Observable<SoundModel[]> {
        return this.http.get<SoundModel[]>(this.url);
    }

    public addSound(item: SoundModel): Observable<SoundModel> {
        return this.http.post<SoundModel>(`${this.url}`, item);
    }

    public deleteSound(id: number) {
        return this.http.delete(`${this.url}/${id}`);
    }
}
