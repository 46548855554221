import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs";
import { OnboardingItem } from "src/app/models/onboarding.model";
import { OnboardingService } from "src/app/services/onboarding.service";
import { AddOnboardingItem, AddOnboardingStep, ApproveOnboardingItem, DeleteOnboardingItem, DeleteOnboardingStep, GetOnboarding, GetOnboardingItem, UpdateOnboardingItem, UpdateOnboardingStep } from "./onboarding.action";

type OnboardingStateModel = OnboardingItem[];

@State<OnboardingStateModel>({
    name: 'OnboardingState',
    defaults: [],
})
@Injectable()
export class OnboardingState {
    private service = inject(OnboardingService);

    @Selector()
    static getOnboardingItems(state: OnboardingStateModel) {
        return state;
    }

    @Selector()
    static getOnboardingItemByIndex(state: OnboardingStateModel) {
        return (id: number) => {
            return state.find((a) => a.id === id);
        };
    }

    @Action(GetOnboarding)
    fetchOnboardingItems(ctx: StateContext<OnboardingStateModel>, action: GetOnboarding) {
        if (ctx.getState().length > 0) return;

        return this.service.getOnboardingItems().pipe(
            tap(items => ctx.setState(items))
        );
    }

    @Action(GetOnboardingItem)
    getOnboardingItem(ctx: StateContext<OnboardingStateModel>, action: GetOnboardingItem) {
        const state = ctx.getState();
        const eTemplate = state.find(es => es.id == action.payload);

        return this.service.getOnboardingItem(action.payload).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(AddOnboardingItem)
    addOnboardingItem(ctx: StateContext<OnboardingStateModel>, action: AddOnboardingItem) {
        const newitem = action.payload;
        const state = ctx.getState();

        return this.service.addOnboardingItem(newitem).pipe(
            tap((res) => {
                ctx.setState([...state, res])
            })
        );
    }

    @Action(UpdateOnboardingItem)
    updateOnboardingItem(ctx: StateContext<OnboardingStateModel>, action: UpdateOnboardingItem) {
        const newitem = action.payload;
        const state = ctx.getState();

        return this.service.updateOnboardingItem(newitem).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(ApproveOnboardingItem)
    approveOnboardingItem(ctx: StateContext<OnboardingStateModel>, action: ApproveOnboardingItem) {
        const state = ctx.getState();

        return this.service.approve(action.payload).pipe(
            tap((res) => {
                const resState = state.map(es => {
                    if (es.id == res.id) {
                        return res;
                    }
                    return es;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(DeleteOnboardingItem)
    deleteOnboardingItem(ctx: StateContext<OnboardingStateModel>, action: DeleteOnboardingItem) {
        const state = ctx.getState();

        return this.service.deleteOnboardingItem(action.payload).pipe(
            tap(() => {
                const updatedState = state.filter((es: OnboardingItem) => es.id !== action.payload);
                ctx.setState([...updatedState]);
            })
        );
    }

    @Action(AddOnboardingStep)
    addOnboardingStep(ctx: StateContext<OnboardingStateModel>, action: AddOnboardingStep) {
        const newitem = action.payload;
        const state = ctx.getState();

        return this.service.addStep(newitem).pipe(
            tap((res) => {
                const resState = state.map(item => {
                    if (item.id == newitem.onboardingSequenceId) {
                        item.onboardingSteps = [...item.onboardingSteps, res];
                        return item;
                    }
                    return item;
                })
                ctx.setState(resState);
            })
        );
    }

    @Action(UpdateOnboardingStep)
    updateOnboardingStep(ctx: StateContext<OnboardingStateModel>, action: UpdateOnboardingStep) {
        const state = ctx.getState();

        return this.service.updateStep(action.payload).pipe(tap((res) => {
            const resState = state.map(os => {
                if (os.onboardingSteps && os.onboardingSteps.length) {
                    const steps = os.onboardingSteps.map(step => {
                        return step.id == action.payload.id ?
                            { ...res } : step
                    });
                    os.onboardingSteps = steps;
                }
                return os;
            });

            ctx.setState(resState);
        }));
    }

    @Action(DeleteOnboardingStep)
    deleteOnboardingStep(ctx: StateContext<OnboardingStateModel>, action: DeleteOnboardingStep) {
        const state = ctx.getState();

        return this.service.deleteStep(action.payload).pipe(
            tap(() => {
                const updatedState = state.map(os => {
                    if (os.onboardingSteps && os.onboardingSteps.length) {
                        let steps = os.onboardingSteps.filter(step => step.id !== action.payload);

                        steps = steps.map((step, index) => ({
                            ...step,
                            sortOrder: index + 1
                        }));

                        os = {
                            ...os,
                            onboardingSteps: steps
                        };
                    }
                    return os;
                });

                ctx.setState(updatedState);
            })
        );
    }

}
