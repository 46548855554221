import { PromptModel } from "src/app/models/notifications/prompt.model";

export class GetPrompts {
    static readonly type = '[Prompts] Get';
    constructor(public payload:
        {
            sortBy: string,
            sortDirection: string
        }
    ) { }
}

export class AddPrompt {
    static readonly type = '[Prompt] Add';
    constructor(public payload: PromptModel) { }
}

export class UpdatePrompt {
    static readonly type = '[Prompt] Update';
    constructor(public payload: PromptModel) { }
}

export class DeletePrompt {
    static readonly type = '[Prompt] Delete';
    constructor(public payload: number) { }
}