import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { concatMap, first, forkJoin, map, Observable, tap } from "rxjs";
import { entLanguage, LocalizationEntity, LocalizationLabelDto, LocalizationType } from "src/app/models/localization/localization-label-dto";
import { LocalizationService } from "src/app/services/localization.service";
import { AddLanguage, AddLanguageApproval, DeleteLanguage, GenerateAllLabelsWithMissingTranslations, GetAllLocalizationDto, UpdateLabelValue, UpdateLocalization, UpdateLocalizationEntityScreenshots } from "./localization.action";

export class LocalizationStateModel {
    labels: LocalizationLabelDto[] = [];
    languages: entLanguage[] = [];
    structuredLocalization: LocalizationType[] = [];
}

@State<LocalizationStateModel>({
    name: 'localization',
    defaults: {
        labels: [],
        languages: [],
        structuredLocalization: []
    }
})
@Injectable()
export class LocalizationState {
    constructor(private service: LocalizationService) { }

    @Selector()
    static getAllLabelsDto(state: LocalizationStateModel): LocalizationLabelDto[] {
        return state.labels;
    }

    @Selector()
    static getEnglishLabelsByTypePKey(state: LocalizationStateModel) {
        return (typePKey: number): LocalizationLabelDto[] => {
            return state.labels.filter(label => label.languageCode === 'en-US' && label.typePKey === typePKey);
        };
    }

    @Selector()
    static getStructuredLocalization(state: LocalizationStateModel): LocalizationType[] {
        return state.structuredLocalization;
    }

    @Selector()
    static getLanguages(state: LocalizationStateModel): entLanguage[] {
        return state.languages;
    }

    @Selector()
    static getLocalizationEntityByPKeyAndTypePKey(state: LocalizationStateModel) {
        return (pKey: number, typePKey: number): LocalizationEntity | undefined => {
            const type = state.structuredLocalization.find(t => t.typePKey === typePKey);
            if (type) {
                return type.entities.find(entity => entity.pKey === pKey);
            }
            return undefined;
        };
    }

    @Selector()
    static getLabelsByLangTypeAndEntityName(state: LocalizationStateModel) {
        return (lang: number, typePKey: number, entityName: string): LocalizationLabelDto[] => {
            const language = state.languages.find(l => l.pKey === lang);
            const languageCode = language ? language.code : 'en-US'; // Default to 'en-US' if not found

            return state.labels.filter(label =>
                label.typePKey === typePKey &&
                label.languageCode.toLowerCase() == languageCode.toLowerCase() &&
                label.localizationEntityName.toLowerCase() === entityName.toLowerCase()
            );
        };
    }

    @Action(GetAllLocalizationDto)
    fetchAllLocalizationDto(ctx: StateContext<LocalizationStateModel>) {
        const state = ctx.getState();

        if (state.labels.length > 0 && state.structuredLocalization.length > 0) {
            return;
        }

        const summaries$ = forkJoin([
            this.service.getEntitiesSummary(1).pipe(first()),
            this.service.getEntitiesSummary(2).pipe(first()),
            this.service.getEntitiesSummary(3).pipe(first()),
            this.service.getEntitiesSummary(4).pipe(first())
        ]).pipe(
            map(results => [].concat(...results)) // Flatten the array of arrays into a single array
        );

        const languages$: Observable<entLanguage[]> = this.service.getAllLanguageCodes();
        const labels$: Observable<LocalizationLabelDto[]> = this.service.getAllLabelsAndValues();
        const screenshots$: Observable<LocalizationEntity[]> = this.service.getLocalizationEntitieswithScreenshots();

        forkJoin([languages$, labels$, screenshots$, summaries$]).pipe(
            tap(([languages, labels, screenshots, summaries]) => {
                ctx.setState({
                    ...state,
                    labels: labels,
                    languages: languages,
                    structuredLocalization: this.transformToStructuredLocalization(labels, languages, screenshots, summaries)
                });

                // Dispatch the GenerateAllLabelsWithMissingTranslations action
                ctx.dispatch(new GenerateAllLabelsWithMissingTranslations({ labels, languages }));
            })
        ).subscribe();
    }

    @Action(GenerateAllLabelsWithMissingTranslations)
    generateAllLabelsWithMissingTranslations(ctx: StateContext<LocalizationStateModel>, action: GenerateAllLabelsWithMissingTranslations) {
        const state = ctx.getState();
        const englishLabels = action.payload.labels.filter(label => label.languageCode === 'en-US');
        const allLabels = [...action.payload.labels];

        englishLabels.forEach(enLabel => {
            action.payload.languages.forEach(language => {
                if (language.name !== 'English') {
                    const existingLabel = allLabels.find(
                        label => label.labelName === enLabel.labelName
                            && label.languageCode === language.code
                            && label.localizationEntityName === enLabel.localizationEntityName
                            && label.typePKey === enLabel.typePKey
                    );
                    if (!existingLabel) {
                        allLabels.push({
                            ...enLabel,
                            languageCode: language.code,
                            labelValue: '',
                        });
                    }
                }
            });
        });

        ctx.setState({
            ...state,
            labels: allLabels,
        });
    }

    @Action(UpdateLabelValue)
    updateLabelValue(ctx: StateContext<LocalizationStateModel>, action: UpdateLabelValue) {
        const state = ctx.getState();
        const newLabel = action.payload;

        this.service.updateLabelValue(newLabel).subscribe(() => {
            const updatedLabels = state.labels.map((label) => {
                if (label.labelName === newLabel.labelName
                    && label.localizationEntityName === newLabel.localizationEntityName
                    && label.typePKey === newLabel.typePKey
                    && label.languageCode === newLabel.languageCode) {
                    return newLabel;
                }
                return label;
            });

            this.service.getEntitiesSummary(newLabel.typePKey).pipe(first()).subscribe((res) => {
                const typeIndex = state.structuredLocalization.findIndex(t => t.typePKey === newLabel.typePKey);

                const updatedEntities = state.structuredLocalization[typeIndex].entities.map((ent) => {
                    if (ent.name == newLabel.localizationEntityName) {
                        const findres = res.find((entity: any) => entity.localizationEntityPKey == ent.pKey)
                        return { ...ent, progress: findres.progress, labelsCount: findres.labelsCount }
                    }
                    return ent;
                })
                const updatedType = {
                    ...state.structuredLocalization[typeIndex],
                    entities: updatedEntities
                };

                const updatedStructuredLocalization = state.structuredLocalization.map(entType => {
                    return entType.typePKey == newLabel.typePKey ? updatedType : entType;
                })

                ctx.setState({
                    ...state,
                    labels: updatedLabels,
                    structuredLocalization: updatedStructuredLocalization
                });

            })
        });
    }

    private transformToStructuredLocalization(labels: LocalizationLabelDto[], languages: entLanguage[], screenshots: LocalizationEntity[], summaries: any[]): LocalizationType[] {
        const groupedByType: { [key: number]: LocalizationEntity[] } = {};

        labels.forEach(label => {
            if (!groupedByType[label.typePKey]) {
                groupedByType[label.typePKey] = [];
            }

            let entity = groupedByType[label.typePKey].find(e => e.name === label.localizationEntityName);
            if (!entity) {
                const matchingScreenshot = screenshots.find(screenshot => screenshot.name === label.localizationEntityName && screenshot.typePKey === label.typePKey);
                const matchingSummary = summaries.find(summary => summary.localizationEntityPKey === (matchingScreenshot?.pKey || 0));
                entity = {
                    pKey: matchingScreenshot?.pKey || 0,
                    code: matchingScreenshot?.code || '',
                    name: label.localizationEntityName,
                    typePKey: label.typePKey,
                    localizationEntityLanguages: matchingScreenshot?.localizationEntityLanguages || [],
                    localizationLabels: [],
                    localizationEntityScreenshots: matchingScreenshot?.localizationEntityScreenshots || [],
                    progress: matchingSummary ? matchingSummary.progress : 0,
                    labelsCount: matchingSummary ? matchingSummary.labelsCount : 0
                };
                groupedByType[label.typePKey].push(entity);
            }

        });

        return Object.keys(groupedByType).map(typePKey => ({
            typePKey: parseInt(typePKey, 10),
            entities: groupedByType[+typePKey]
        }));
    }

    @Action(AddLanguageApproval)
    addLanguageApproval(ctx: StateContext<LocalizationStateModel>, action: AddLanguageApproval) {
        const state = ctx.getState();

        return this.service.AddLanguageApproval(action.languagePkey, action.typeKey, action.user.sub).pipe(
            tap((res) => {
                const updatedLanguages = state.languages.map(lang => {
                    if (lang.pKey === action.languagePkey) {
                        let updatedApprovals;
                        if (lang.localizationLanguageApprovals.find(app => app.typePKey === action.typeKey)) {
                            updatedApprovals = lang.localizationLanguageApprovals.map(approval => {
                                if (approval.typePKey === action.typeKey) {
                                    return res;
                                }
                                return approval;
                            });
                        } else {
                            updatedApprovals = [...lang.localizationLanguageApprovals, res];
                        }
                        return {
                            ...lang,
                            localizationLanguageApprovals: updatedApprovals
                        };
                    }
                    return lang;
                });

                ctx.setState({
                    ...state,
                    languages: updatedLanguages
                });
            })
        );
    }

    @Action(DeleteLanguage)
    deleteLanguage(ctx: StateContext<LocalizationStateModel>, action: DeleteLanguage) {
        const state = ctx.getState();

        return this.service.deleteLanguage(action.languagePKey).pipe(
            tap(() => {
                const filteredLanguages = state.languages.filter(l => l.pKey !== action.languagePKey);
                ctx.setState({
                    ...state,
                    languages: filteredLanguages
                });
            })
        );
    }

    @Action(AddLanguage)
    addLanguage(ctx: StateContext<LocalizationStateModel>, action: AddLanguage) {
        return this.service.addLanguage(action.params).pipe(
            tap((newLanguage) => {
                const state = ctx.getState();
                ctx.setState({
                    ...state,
                    languages: [...state.languages, newLanguage]
                });
            })
        );
    }

    @Action(UpdateLocalization)
    updateLocalization(ctx: StateContext<LocalizationStateModel>, action: UpdateLocalization) {
        ctx.setState({
            labels: [],
            languages: [],
            structuredLocalization: []
        });
        ctx.dispatch(new GetAllLocalizationDto);
    }

    @Action(UpdateLocalizationEntityScreenshots)
    updateLocalizationEntityScreenshots(ctx: StateContext<LocalizationStateModel>, action: UpdateLocalizationEntityScreenshots) {
        return this.service.updateScreenshots(action.entityPKey, action.screenshotsToAdd, action.shouldDelete, action.screenshotPkey).pipe(
            concatMap(() => this.service.getSingleLocalizationEntity(action.entityPKey).pipe(first())),
            tap((res) => {
                const state = ctx.getState();
                const typeIndex = state.structuredLocalization.findIndex(t => t.typePKey === res.typePKey);

                if (typeIndex !== -1) {
                    const updatedType = {
                        ...state.structuredLocalization[typeIndex],
                        entities: state.structuredLocalization[typeIndex].entities.map(entity =>
                            entity.pKey === res.pKey ? { ...entity, localizationEntityScreenshots: res.localizationEntityScreenshots } : entity
                        )
                    };

                    const updatedStructuredLocalization = state.structuredLocalization.map(entType => {
                        return entType.typePKey == res.typePKey ? updatedType : entType;
                    })

                    ctx.setState({
                        ...state,
                        structuredLocalization: updatedStructuredLocalization
                    });
                }
            })
        );
    }
}
