export enum MainRoutesEnum {
  landingPage = 'landing-page',
  localozation = 'localization',
  localozationLanguageApproval = 'Localozation-approval',
  Users = 'users',
  Review = 'Review',
  Blog = 'Blog',
  Posts = 'Posts',
  Emails = 'Emails',
  loginCallback = 'callback',
  KPI = 'KPI',
  Notifications = 'Notifications',
  NotificationHub = 'Notification-hub',
  Screenshots = 'Screenshots',
  UserAccess = 'user-access',
}
