import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { tap } from "rxjs";
import { EmailTemplate } from "src/app/models/email";
import { EmailTemplatesService } from "src/app/services/email-template.service";
import { AddEmailTemplate, ApproveEmailTemplate, DeleteEmailTemplate, GetEmailTemplate, GetEmailTemplates, TranslateEmailTemplate, UpdateEmailTemplate } from "./email-template.action";

type EmailTemplatesStateModel = EmailTemplate[];

@State<EmailTemplatesStateModel>({
  name: 'EmailTemplatesState',
  defaults: [],
})
@Injectable()
export class EmailTemplatesState {

  constructor(private service: EmailTemplatesService, private store: Store) { }

  @Selector()
  static getEmailsTemplates(state: EmailTemplatesStateModel) {
    return state;
  }

  @Selector()
  static getTemplateByIndex(state: EmailTemplatesStateModel) {
    return (id: number) => {
      return state.find((a) => a.id === id);
    };
  }

  @Action(GetEmailTemplates)
  fetchEmailsTemplates(ctx: StateContext<EmailTemplatesStateModel>, action: GetEmailTemplates) {
    if (ctx.getState().length > 0) return;

    return this.service.getEmailTemplates().pipe(
      tap(items => ctx.setState(items))
    );
  }

  @Action(GetEmailTemplate)
  fetchEmailsSection(ctx: StateContext<EmailTemplatesStateModel>, action: GetEmailTemplate) {
    const state = ctx.getState();
    const eTemplate = state.find(es => es.id == action.payload);
    if (eTemplate?.translatedEmailTemplates && eTemplate?.translatedEmailTemplates.length > 0) return

    return this.service.getEmailTemplate(action.payload).pipe(
      tap((res) => {
        const resState = state.map(es => {
          if (es.id == res.id) {
            return res;
          }
          return es;
        })
        ctx.setState(resState);
      })
    );
  }

  @Action(AddEmailTemplate)
  addEmailTemplate(ctx: StateContext<EmailTemplatesStateModel>, action: AddEmailTemplate) {
    const newitem = action.payload;
    const state = ctx.getState();

    return this.service.addEmailTemplate(newitem).pipe(
      tap((res) => {
        ctx.setState([...state, res])
      })
    );
  }

  @Action(UpdateEmailTemplate)
  updateEmailTemplate(ctx: StateContext<EmailTemplatesStateModel>, action: UpdateEmailTemplate) {
    const newitem = action.payload;
    const state = ctx.getState();

    return this.service.updateEmailTemplate(newitem).pipe(
      tap((res) => {
        const resState = state.map(es => {
          if (es.id == res.id) {
            return res;
          }
          return es;
        })
        ctx.setState(resState);
      })
    );
  }

  @Action(TranslateEmailTemplate)
  translateEmailTemplate(ctx: StateContext<EmailTemplatesStateModel>, action: TranslateEmailTemplate) {
    const newitem = action.payload;
    const state = ctx.getState();

    return this.service.translate(newitem.id).pipe(
      tap((res) => {
        const resState = state.map(es => {
          if (es.id == res.id) {
            return res;
          }
          return es;
        })
        ctx.setState(resState);
      })
    );
  }

  @Action(ApproveEmailTemplate)
  approveEmailTemplate(ctx: StateContext<EmailTemplatesStateModel>, action: ApproveEmailTemplate) {
    const state = ctx.getState();

    return this.service.approve(action.payload).pipe(
      tap((res) => {
        const resState = state.map(es => {
          if (es.id == res.id) {
            return res;
          }
          return es;
        })
        ctx.setState(resState);
      })
    );
  }

  @Action(DeleteEmailTemplate)
  deleteEmailTemplate(ctx: StateContext<EmailTemplatesStateModel>, action: DeleteEmailTemplate) {
    const state = ctx.getState();

    return this.service.deleteEmailTemplate(action.payload).pipe(
      tap(() => {
        const updatedState = state.filter((es: EmailTemplate) => es.id !== action.payload);
        ctx.setState([...updatedState]);
      })
    );
  }
}
