export const environment = {
  production: false,
  api: {
    url: 'https://api.admin.dev.mytrilo.com/api/',
  },
  auth: {
    identity: {
      issuer: 'https://identity.admin.dev.mytrilo.com',
      clientId: 'MyTriloWebAdminApp',
    },
    scope: 'mytrilo_admin.api openid profile email offline_access',
    KPIHeaderKey: "ee247f62-40b8-4f65-b906-407f22664e6f",
  },
  firebase: {
    apiKey: 'AIzaSyCGNATF4n8SzpSBP1vtqqjCnApA8150EFQ',
    authDomain: 'mytrilo-36498.firebaseapp.com',
    projectId: 'mytrilo-36498',
    storageBucket: 'mytrilo-36498.appspot.com',
    messagingSenderId: '1023698801866',
    appId: '1:1023698801866:web:4732526ee1430283495033',
    measurementId: 'G-78Y8P3MZPH',
    storageFolderName: 'admin-dev',
  },
  deepApiKey: "ac9696d9-5d42-445e-a551-c8ace84bb316:fx",
  huggingfaceKey: "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",
  logo_png: "https://firebasestorage.googleapis.com/v0/b/mytrilo-36498.appspot.com/o/admin-local%2Fmytrilo-light-theme-icon.webp?alt=media&token=250852d0-9a76-4504-a39d-7b08ab009473",
  laptop_verification_png: "https://firebasestorage.googleapis.com/v0/b/mytrilo-36498.appspot.com/o/admin-local%2Flaptop_verification.webp?alt=media&token=20e80dc9-dcf1-4a7b-b945-6706386d5478"
};
