import { CustomerJourneyTemplate } from "src/app/models/notifications/customer-jorney";
import { CustomerJourneyStep } from "src/app/models/notifications/customer-journey-step.model";

export class GetCustomerJourneys {
    static readonly type = '[CustomerJourneys] Get';
    constructor() { }
}

export class GetCustomerJourney {
    static readonly type = '[CustomerJourney] Get';
    constructor(public payload: number) { }
}

export class AddCustomerJourney {
    static readonly type = '[CustomerJourney] Add';
    constructor(public payload: CustomerJourneyTemplate) { }
}

export class UpdateCustomerJourney {
    static readonly type = '[CustomerJourney] Update';
    constructor(public payload: CustomerJourneyTemplate) { }
}

export class TranslateEmailTemplate {
    static readonly type = '[CustomerJourney] Translate';
    constructor(public payload: CustomerJourneyTemplate) { }
}

export class ApproveCustomerJourney {
    static readonly type = '[CustomerJourney] Approve';
    constructor(public payload: number) { }
}

export class DeleteCustomerJourney {
    static readonly type = '[CustomerJourney] Delete';
    constructor(public payload: number) { }
}

export class UpdateCustomerJourneyStep {
    static readonly type = '[CustomerJourneyStep] Update';
    constructor(public payload: CustomerJourneyStep) { }
}

export class ApproveCustomerJourneyStep {
    static readonly type = '[CustomerJourneyStep] Approve';
    constructor(public payload: number) { }
}

export class DeleteCustomerJourneyStep {
    static readonly type = '[CustomerJourneyStep] Delete';
    constructor(public payload: number) { }
}
