import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs";
import { SoundModel } from "src/app/models/sound-model";
import { SoundsService } from "src/app/services/sounds.service";
import { AddSound, DeleteSound, GetSounds } from "./sounds.action";

type SoundsStateModel = SoundModel[];

@State<SoundsStateModel>({
    name: 'SoundsState',
    defaults: [],
})
@Injectable()
export class SoundsState {
    private service = inject(SoundsService);

    @Selector()
    static getSounds(state: SoundsStateModel) {
        return state;
    }

    @Action(GetSounds)
    fetchSounds(ctx: StateContext<SoundsStateModel>, action: GetSounds) {
        if (ctx.getState().length > 0) return;

        return this.service.getSounds().pipe(
            tap(items => ctx.setState(items))
        );
    }

    @Action(AddSound)
    addOnboardingItem(ctx: StateContext<SoundsStateModel>, action: AddSound) {
        const newitem = action.payload;
        const state = ctx.getState();

        return this.service.addSound(newitem).pipe(
            tap((res) => {
                ctx.setState([...state, res])
            })
        );
    }

    @Action(DeleteSound)
    deleteOnboardingItem(ctx: StateContext<SoundsStateModel>, action: DeleteSound) {
        const state = ctx.getState();

        return this.service.deleteSound(action.payload).pipe(
            tap(() => {
                const updatedState = state.filter((es: SoundModel) => es.id !== action.payload);
                ctx.setState([...updatedState]);
            })
        );
    }
}
